import React, { FC } from 'react';
import { Text, Avatar, AvatarSize, Button } from 'wix-ui-tpa';

import { classes } from './NewActionableMemberRow.st.css';
import { MemberListItem } from '../../../../../types';

interface MemberData {
  member: MemberListItem;
  buttonText: string;
  onAction?: () => void;
}

export const NewActionableMemberRow: FC<MemberData> = ({
  member,
  onAction,
  buttonText,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.leftSide}>
        <Avatar
          size={AvatarSize.medium}
          name={member.name}
          src={member.photo}
        />
        <div className={classes.description}>
          <Text>{member.name}</Text>
        </div>
      </div>

      <Button upgrade onClick={onAction} className={classes.button}>
        {buttonText}
      </Button>
    </div>
  );
};
