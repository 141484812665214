import React, { FC } from 'react';
import { useSettings } from '@wix/tpa-settings/react';

import { FieldGroup } from '../../FieldGroup';
import { SectionHeader } from '../../common/SectionHeader';
import { classes } from './Account.st.css';
import { LoginEmail } from './LoginEmail';
import { useFormFields } from '../../../../../contexts/form';
import settingsParams from '../../../settingsParams';
import { Experiment, FieldSection } from '../../../../../types';
import { useExperiments } from '@wix/yoshi-flow-editor';

export const Account: FC = () => {
  const fields = useFormFields();
  const settings = useSettings();
  const { experiments } = useExperiments();

  return (
    <div className={classes.root}>
      <SectionHeader
        title={settings.get(settingsParams.accountSectionTitle)}
        subtitle={settings.get(settingsParams.accountSectionSubtitle)}
      />
      {!experiments.enabled(Experiment.ChangeLoginInfo) && <LoginEmail />}
      <FieldGroup fields={fields} section={FieldSection.GENERAL} />
    </div>
  );
};
