import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Button, ButtonPriority as PRIORITY } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { classes, st } from './CTAGroup.st.css';
import { useModal } from '../../../../../contexts/modals/hooks';
import { DiscardChangesModal } from '../../modals';
import { DataHook } from '../../../../../constants/DataHook';
import settingsParams from '../../../settingsParams';
import { Field } from '../../../../../types';

export const CTAGroup: FC = () => {
  const { isMobile, isEditor } = useEnvironment();
  const { showModal } = useModal();
  const { resetForm, dirty } = useFormikContext<Field[]>();
  const settings = useSettings();
  const buttonsDisabled = !dirty && !isEditor;

  return (
    <div className={st(classes.root, isMobile ? classes.mobile : undefined)}>
      <div className={classes.container}>
        <Button
          upgrade
          priority={PRIORITY.secondary}
          onClick={() =>
            showModal(DiscardChangesModal, { onConfirm: resetForm })
          }
          data-hook={DataHook.DiscardChangesButton}
          className={st(classes.discardButton, { disabled: !dirty })}
          disabled={buttonsDisabled}
        >
          <div className={classes.buttonContent}>
            {settings.get(settingsParams.discardButtonText)}
          </div>
        </Button>

        <Button
          className={classes.saveButton}
          data-hook={DataHook.SaveAccountButton}
          type="submit"
          priority={PRIORITY.primary}
          disabled={buttonsDisabled}
          upgrade
        >
          <div className={classes.buttonContent}>
            {settings.get(settingsParams.saveButtonText)}
          </div>
        </Button>
      </div>
    </div>
  );
};
