import React, { FC } from 'react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { Text, TextButton } from 'wix-ui-tpa';

import { classes } from './LoginEmail.st.css';
import { useMember } from '../../../../../../contexts/widget';
import { Experiment } from '../../../../../../types';

export const LoginEmail: FC = () => {
  const { t } = useTranslation();
  const { loginEmail } = useMember();
  const { experiments } = useExperiments();

  return (
    <div className={classes.root}>
      <Text className={classes.title} tagName="div">
        {t('app.widget.login-email.title')}:
      </Text>

      <Text className={classes.title} tagName="div">
        {loginEmail}
      </Text>

      {experiments.enabled(Experiment.ChangeLoginInfo) ? (
        <TextButton>{t('app.widget.login-email.change-button')}</TextButton>
      ) : (
        <Text className={classes.subtitle} tagName="div">
          {t('app.widget.login-email.subtitle')}
        </Text>
      )}
    </div>
  );
};
